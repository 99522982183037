import Vue from 'vue'
import Vuex, { Commit } from 'vuex'
import pathify from 'vuex-pathify'
import { auth } from './modules/auth'
import { loader } from './modules/loader'
import { client } from './modules/client'
import { region } from './modules/region'
import { regionvariables } from './modules/region-variables'
import { coversheetbuilder } from './modules/coversheetbuilder'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modalNotCloseOutside: true,
    modalWithoutAnimation: true,
    notifications: [] as any,
    board: {
      boardName: null
    },
    boardMember: {
      emailAddress: ''
    }
  },
  mutations: {
    changeBoardName (state, payload) {
      state.board.boardName = payload
    },
    setMemberEmail (state, payload) {
      state.boardMember.emailAddress = payload
    },
    addNotification (state, notification) {
      state.notifications.push({
        ...notification,
        id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
      })
    },
    removeNotification (state, notificationToRemove) {
      state.notifications = state.notifications.filter((notification: any) => {
        return notification.id !== notificationToRemove.id
      })
    }
  },
  actions: {
    addNotification ({ commit }: { commit: Commit }, notification) {
      commit('addNotification', notification)
    },
    removeNotification ({ commit }: { commit: Commit }, notification) {
      commit('removeNotification', notification)
    }
  },
  modules: {
    auth,
    loader,
    client,
    region,
    regionvariables,
    coversheetbuilder
  },
  plugins: [pathify.plugin, createPersistedState({
    paths: ['auth', 'client']
  })]
})
