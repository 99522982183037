<template>
  <div v-if="notifications.length" class="notifications">
    <alert-message
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    ></alert-message>
  </div>
</template>

<script>
import Vue from 'vue'
import AlertMessage from '@/components/AlertMessage'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'Alert',
  components: {
    AlertMessage
  },
  computed: mapState(['notifications'])
})
</script>
