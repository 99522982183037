import store from '../store'

export const parseColumnContent = (content: string) => {
  const datetimeVariables = store.getters['regionvariables/datetimeVariables']
  const datetimeFormats = store.getters['regionvariables/datetimeFormats']
  const variableMatches = content.match(/[[*](.*?)[*]]/g) ?? []
  let modifiedContent = content
  variableMatches.forEach(matchWord => {
    const rawMatchWord = matchWord.substring(2).slice(0, -2)
    if (datetimeVariables[rawMatchWord] !== undefined) {
      const variableValue = datetimeVariables[rawMatchWord].value
      modifiedContent = modifiedContent.replaceAll(matchWord, variableValue)
    }
    if (datetimeFormats[rawMatchWord] !== undefined) {
      const variableValue = datetimeVariables[rawMatchWord].value
      modifiedContent = modifiedContent.replaceAll(matchWord, variableValue)
    }
  })
  return modifiedContent
}

export const parseRegion = (regions: Array<Record<string, unknown>>) => {
  Object.keys(regions).forEach(regionKey => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const currentRegion = regions[regionKey]
    const blocks = ['begin_block', 'end_block', 'item_container', 'end_block']
    blocks.forEach(block => {
      if (currentRegion[block] !== undefined && currentRegion[block].rows !== undefined) {
        const currentRegionBlockRows = currentRegion[block].rows
        Object.keys(currentRegionBlockRows).forEach(rowKey => {
          if (currentRegionBlockRows[rowKey].columns !== undefined) {
            Object.keys(currentRegionBlockRows[rowKey].columns).forEach(columnKey => {
              const currentColumn = currentRegionBlockRows[rowKey].columns[columnKey]
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              regions[regionKey][block].rows[rowKey].columns[columnKey].parsedContent = parseColumnContent(currentColumn.content)
            })
          }
        })
      }
    })
  })
  return regions
}
