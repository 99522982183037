import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueNoty from 'vuejs-noty'
import VModal from 'vue-js-modal'
import { Model } from 'vue-api-query'
import moment from 'moment'

declare module 'axios' {
  export interface AxiosRequestConfig {
    showLoader: boolean;
    showNotifications: boolean;
  }
}

Vue.config.productionTip = false

Vue.use(VModal, { dynamic: true, injectModalsContainer: true })

Vue.prototype.moment = moment

Vue.use(VueNoty, {
  theme: 'metroui',
  layout: 'topCenter',
  closeWith: ['click', 'button'],
  animation: {
    open: 'animated fadeInRight',
    close: 'animated fadeOutRight'
  }
})

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
axios.defaults.withCredentials = true
axios.defaults.showLoader = true
axios.defaults.showNotifications = true

// inject global axios instance as http client to Model
Model.$http = axios

new Vue({
  router,
  store,
  vuetify,
  created () {
    axios.interceptors.request.use(
      config => {
        if (config.showLoader) {
          store.dispatch('loader/pending')
        }
        return config
      },
      error => {
        if (error.config.showLoader) {
          store.dispatch('loader/done')
        }
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(
      response => {
        // Display Noty success message
        let status = 'success'
        if (response.data) {
          if (response.data.status) {
            status = response.data.status
          }

          if (response.data.message && response.config.showNotifications) {
            this.$store.dispatch('addNotification', {
              type: status,
              message: response.data.message
            })
          }
        }

        if (response.config.showLoader) {
          store.dispatch('loader/done')
        }

        return response
      },
      error => {
        const response = error.response

        // Skip login/logout URLs
        if (response && response.status === 401 && (
          response.config.url !== 'login' &&
          response.config.url !== 'logout' &&
          response.config.url !== 'password/email'
        )) {
          // Logout the user if response is 401
          this.$store.dispatch('auth/logout')
        }

        let message = 'Request failed'
        if (response.data && response.data.message) {
          message = response.data.message
        }

        if (response.config.showNotifications) {
          this.$store.dispatch('addNotification', {
            type: 'error',
            message
          })
        }

        if (response.config.showLoader) {
          store.dispatch('loader/done')
        }

        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
