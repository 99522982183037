<template>
  <v-alert prominent
           dense
           :value="true"
           :type="notification.type"
           dismissible>
    {{ notification.message }}
  </v-alert>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'AlertMessage',
  props: ['notification'],
  data () {
    return {
      timeout: null
    }
  },
  created () {
    this.timeout = setTimeout(() => {
      this.removeNotification(this.notification)
    }, 3000)
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
  },
  methods: mapActions(['removeNotification'])
})
</script>
