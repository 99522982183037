







import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import Alert from '@/components/Alert.vue'

Sentry.init({
  dsn: 'https://24d7c66cec894292b9f2c635b3bdd2bd@o465822.ingest.sentry.io/5602242',
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing()
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

export default Vue.extend({
  name: 'App',
  components: {
    Alert
  }
})
