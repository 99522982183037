import router from '@/router'
import axios from 'axios'
import { Commit } from 'vuex'

interface AuthState {
  user: User | null;
}

interface User {
  id: number;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
}

interface LoginPayload {
  name: string;
  password: string;
  payload: string;
}

interface RegisterPayload {
  email: string;
  firstName: string;
  lastName: string;
  payload: string;
}

const state: AuthState = {
  user: null
}

export const auth = {
  namespaced: true,
  state,
  mutations: {
    setUser (state: AuthState, user: User) {
      state.user = user
    },
    clearUser (state: AuthState) {
      state.user = null
      return router.push({ name: 'Login' })
    }
  },
  actions: {
    login ({ commit }: { commit: Commit }, loginPayload: LoginPayload) {
      return axios.get('/sanctum/csrf-cookie').then(() => {
        return axios
          .post('/login', loginPayload)
          .then(response => {
            commit('setUser', response.data.user)
            return response
          })
      })
    },

    register ({ commit }: { commit: Commit }, registerPayload: RegisterPayload) {
      return axios.get('/sanctum/csrf-cookie').then(() => {
        return axios
          .post('/api/register', registerPayload)
          .then(response => {
            if (response.data.status === 'success') {
              commit('setUser', response.data.user)
            }
            return response
          })
      })
    },

    logout ({ commit }: { commit: Commit }) {
      return axios.get('/sanctum/csrf-cookie').then(() => {
        return axios
          .post('/logout')
          .then(response => {
            commit('clearUser')
            return response
          })
      })
    }
  },
  getters: {
    isLogged: (state: AuthState) => !!state.user,
    user: (state: AuthState) => state.user
  }
}
