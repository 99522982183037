import { Commit } from 'vuex'
// import Vue from 'vue'
// import { parseRegion } from '@/helpers/templateBuilderHelper'

interface CoverSheetBuilderState {
  rows: Array<Record<string, unknown>>;
  selectedRow: object;
  previewRegions: boolean;
  previewRegion: boolean;
  previewRows: boolean;
  previewRow: boolean;
  triggeredClose: boolean;
  contentHasBeenModified: boolean;
}

const initialState: CoverSheetBuilderState = {
  rows: [],
  selectedRow: {
    columns: {},
    style: {}
  },
  previewRegions: false,
  previewRegion: false,
  previewRows: false,
  previewRow: false,
  triggeredClose: false,
  contentHasBeenModified: false
}

const state = { ...initialState }

export const coversheetbuilder = {
  namespaced: true,
  state,
  mutations: {
    setRows (state: CoverSheetBuilderState, payload: Array<Record<string, unknown>>) {
      // state.rows = parseRegion(payload)
      state.rows = payload
    },
    setSelectedRow (state: CoverSheetBuilderState, payload: object) {
      state.selectedRow = payload
    },
    setPreviewRows (state: CoverSheetBuilderState, payload: boolean) {
      state.previewRows = payload
    },
    setPreviewRow (state: CoverSheetBuilderState, payload: boolean) {
      state.previewRow = payload
    },
    RESET_MODULE (state: CoverSheetBuilderState) { // To reset the entire module
      Object.assign(state, initialState)
    },
    clearSelelectedRow (state: CoverSheetBuilderState) {
      state.selectedRow = {}
    },
    triggeredClose (state: CoverSheetBuilderState) {
      state.triggeredClose = !state.triggeredClose
      // clearTimeout(timeout)
    },
    setContentHasBeenModified (state: CoverSheetBuilderState, payload: boolean) {
      state.contentHasBeenModified = payload ?? true
    }
  },
  actions: {
    setRows ({ commit }: { commit: Commit }, payload: object) {
      commit('setRows', payload)
    },
    setSelectedRow ({ commit }: { commit: Commit }, payload: object) {
      commit('setSelectedRow', payload)
    },
    setPreviewRows ({ commit }: { commit: Commit }, payload: boolean) {
      commit('setPreviewRows', payload)
    },
    setPreviewRow ({ commit }: { commit: Commit }, payload: boolean) {
      commit('setPreviewRow', payload)
    },
    RESET_MODULE ({ commit }: { commit: Commit }) {
      console.log('comitting reset module')
      commit('RESET_MODULE')
    },
    clearSelelectedRow ({ commit }: { commit: Commit }) {
      commit('clearSelelectedRow')
    },
    setContentHasBeenModified ({ commit }: { commit: Commit }, payload: boolean) {
      commit('setContentHasBeenModified', payload)
    },
    triggeredClose ({ commit }: { commit: Commit }) {
      commit('triggeredClose')

      setTimeout(() => {
        console.log('WAITING FOR TRIGGERED CLOSE')
        commit('triggeredClose')
      }, 300)
    }
  },
  getters: {
    selectedRow: (state: CoverSheetBuilderState) => state.selectedRow
  }
}
