import axios from 'axios'
import { Commit } from 'vuex'

interface ClientState {
  availableClientList: Client | null;
  activeClient: Client | null;
}

interface Client {
  clientList: string;
}

const state: ClientState = {
  availableClientList: null,
  activeClient: null
}

export const client = {
  namespaced: true,
  state,
  mutations: {
    setClientList (state: ClientState, client: Client) {
      state.availableClientList = client
    },
    clearClient (state: ClientState) {
      state.availableClientList = null
    },
    setActiveClient (state: ClientState, client: Client) {
      state.activeClient = client
    }
  },
  actions: {
    clientList ({ commit }: { commit: Commit }) {
      return axios.get('/sanctum/csrf-cookie').then(() => {
        return axios
          .get('api/client-list')
          .then(response => {
            commit('setClientList', response.data)
            commit('setActiveClient', response.data[0])
            return response
          })
      })
    }
  },
  getters: {
    client: (state: ClientState) => state.availableClientList
  }
}
