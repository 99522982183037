import { Commit } from 'vuex'
import Vue from 'vue'
import { parseRegion } from '@/helpers/templateBuilderHelper'

interface RegionState {
  regions: Array<Record<string, unknown>>;
  builderType: string;
  selectedRegion: object;
  selectedRow: object;
  previewRegions: boolean;
  previewRegion: boolean;
  previewRow: boolean;
  triggeredClose: boolean;
  contentHasBeenModified: boolean;
}

const initialState: RegionState = {
  regions: [],
  builderType: '',
  selectedRegion: {},
  selectedRow: {
    begin_block: {
      rows: {
        columns: {}
      }
    },
    end_block: {
      rows: {}
    },
    item_container: {
      rows: {}
    },
    no_item_text: {
      rows: {}
    }
  },
  previewRegions: false,
  previewRegion: false,
  previewRow: false,
  triggeredClose: false,
  contentHasBeenModified: false
}

const state = { ...initialState }

export const region = {
  namespaced: true,
  state,
  mutations: {
    setRegions (state: RegionState, payload: Array<Record<string, unknown>>) {
      state.regions = parseRegion(payload)
    },
    setSelectedRegion (state: RegionState, payload: object) {
      state.selectedRegion = payload
    },
    setSelectedRow (state: RegionState, payload: object) {
      state.selectedRow = payload
    },
    setBuilderType (state: RegionState, payload: string) {
      state.builderType = payload
    },
    setPreviewRegions (state: RegionState, payload: boolean) {
      state.previewRegions = payload
    },
    setPreviewRegion (state: RegionState, payload: boolean) {
      state.previewRegion = payload
    },
    setPreviewRow (state: RegionState, payload: boolean) {
      state.previewRow = payload
    },
    RESET_MODULE (state: RegionState) { // To reset the entire module
      Object.assign(state, initialState)
    },
    clearSelelectedRegionAndRow (state: RegionState) {
      state.selectedRegion = {}
      state.selectedRow = {}
    },
    triggeredClose (state: RegionState) {
      state.triggeredClose = !state.triggeredClose
      // clearTimeout(timeout)
    },
    setContentHasBeenModified (state: RegionState, payload: boolean) {
      state.contentHasBeenModified = payload ?? true
    }
  },
  actions: {
    setRegions ({ commit }: { commit: Commit }, payload: object) {
      commit('setRegions', payload)
    },
    setSelectedRegion ({ commit }: { commit: Commit }, payload: object) {
      commit('setSelectedRegion', payload)
    },
    setSelectedRow ({ commit }: { commit: Commit }, payload: object) {
      commit('setSelectedRow', payload)
    },
    setBuilderType ({ commit }: { commit: Commit }, payload: string) {
      commit('setBuilderType', payload)
    },
    setPreviewRegion ({ commit }: { commit: Commit }, payload: boolean) {
      commit('setPreviewRegion', payload)
    },
    setPreviewRegions ({ commit }: { commit: Commit }, payload: boolean) {
      commit('setPreviewRegions', payload)
    },
    setPreviewRow ({ commit }: { commit: Commit }, payload: boolean) {
      commit('setPreviewRow', payload)
    },
    RESET_MODULE ({ commit }: { commit: Commit }) {
      console.log('comitting reset module')
      commit('RESET_MODULE')
    },
    clearSelelectedRegionAndRow ({ commit }: { commit: Commit }) {
      commit('clearSelelectedRegionAndRow')
    },
    setContentHasBeenModified ({ commit }: { commit: Commit }, payload: boolean) {
      commit('setContentHasBeenModified', payload)
    },
    triggeredClose ({ commit }: { commit: Commit }) {
      commit('triggeredClose')

      setTimeout(() => {
        console.log('WAITING FOR TRIGGERED CLOSE')
        commit('triggeredClose')
      }, 300)
    }
  },
  getters: {
    selectedRegion: (state: RegionState) => state.selectedRegion
  }
}
