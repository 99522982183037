import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#18214f',
        secondary: '#65ada5',
        secondaryLight: '#f0f9fd',
        buttonRegular: '#F0C690',
        darkGrey: '#444444',
        regularGrey: '#888888',
        lightGrey: '#cccccc',
        veryLightGrey: '#f4f4f4',
        almostWhiteGrey: '#f8f8f8',
        important: '#D06C6C',
        positive: '#7DD97D',
        accentBlue: '#18214f',
        accentGreen: '#BCCC59',
        accentRed: '#cc5432',
        accentYellow: '#ffb74d'
      }
    }
  }
})
