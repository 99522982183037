import { Commit } from 'vuex'

interface Loader {
  loading: boolean;
  requestsPending: number;
}

export const loader = {
  namespaced: true,
  state: {
    loading: false,
    requestsPending: 0
  },
  actions: {
    show ({ commit }: { commit: Commit }) {
      commit('show')
    },
    hide ({ commit }: { commit: Commit }) {
      commit('hide')
    },
    pending ({ commit }: { commit: Commit }) {
      commit('pending')
    },
    done ({ commit }: { commit: Commit }) {
      commit('done')
    }
  },
  mutations: {
    show (state: Loader) {
      state.loading = true
    },
    hide (state: Loader) {
      state.loading = false
    },
    pending (state: Loader) {
      if (state.requestsPending === 0) {
        state.loading = true
      }

      state.requestsPending++
    },
    done (state: Loader) {
      if (state.requestsPending >= 1) {
        state.requestsPending--
      }

      if (state.requestsPending <= 0) {
        state.loading = false
      }
    }
  }
}
