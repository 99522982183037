import { Commit } from 'vuex'
import { commit } from 'vuex-pathify'
// import axios from 'axios'

interface RegionVariablesState {
  datetimeVariables: Array<Record<string, unknown>>;
  datetimeFormats: Array<Record<string, unknown>>;
  variableResolutions: Array<Record<string, unknown>>;
}

const initialState: RegionVariablesState = {
  datetimeVariables: [],
  datetimeFormats: [],
  variableResolutions: []
}

const state = { ...initialState }

export const regionvariables = {
  namespaced: true,
  state,
  mutations: {
    setDatetimeVariables (state: RegionVariablesState, payload: Array<Record<string, unknown>>) {
      state.datetimeVariables = payload
    },
    setDatetimeFormats (state: RegionVariablesState, payload: Array<Record<string, unknown>>) {
      state.datetimeFormats = payload
    },
    setVariableResolutions (state: RegionVariablesState, payload: Array<Record<string, unknown>>) {
      state.variableResolutions = payload
    }
  },
  actions: {
    setDatetimeVariables ({ commit }: { commit: Commit }, payload: object) {
      commit('setDatetimeVariables', payload)
    },
    setDatetimeFormats ({ commit }: { commit: Commit }, payload: object) {
      commit('setDatetimeFormats', payload)
    },
    setVariableResolutions ({ commit }: { commit: Commit }, payload: object) {
      commit('setVariableResolutions', payload)
    }
  },
  getters: {
    datetimeVariables: (state: RegionVariablesState) => state.datetimeVariables,
    datetimeFormats: (state: RegionVariablesState) => state.datetimeFormats,
    variableResolutions: (state: RegionVariablesState) => state.variableResolutions
  }
}
