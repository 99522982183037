import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      auth: true
    }
  },
  {
    path: '/meetings',
    name: 'Meetings',
    component: () => import('@/views/Meetings/Meetings.vue'),
    meta: {
      title: 'Meetings',
      auth: true
    }
  },
  {
    path: '/meeting-minutes',
    name: 'MeetingMinutes',
    component: () => import('@/views/Meetings/MeetingMinutes.vue'),
    meta: {
      title: 'Meeting Minutes',
      auth: true
    }
  },
  {
    path: '/meeting-uploads',
    name: 'MeetingUploads',
    component: () => import('@/views/Meetings/MeetingUploads.vue'),
    meta: {
      title: 'Meeting Uploads',
      auth: true
    }
  },
  {
    path: '/meeting-finalized',
    name: 'MeetingFinalized',
    component: () => import('@/views/Meetings/MeetingFinalized.vue'),
    meta: {
      title: 'Meeting Finalized',
      auth: true
    }
  },
  {
    path: '/meeting-packet',
    name: 'MeetingPacket',
    component: () => import('@/views/Meetings/MeetingPacket.vue'),
    meta: {
      title: 'Meeting Packet',
      auth: true
    }
  },
  {
    path: '/meeting-items',
    name: 'MeetingItems',
    component: () => import('@/views/Meetings/MeetingItems.vue'),
    meta: {
      title: 'Meeting Items',
      auth: true
    }
  },
  {
    path: '/items',
    name: 'Items',
    component: () => import('@/views/Items/Items.vue'),
    meta: {
      title: 'Items',
      auth: true
    }
  },
  {
    path: '/item-attachments/:itemId',
    name: 'ItemAttachments',
    props: true,
    component: () => import('@/views/Items/ItemAttachments.vue'),
    meta: {
      title: 'Item Attachments',
      auth: true
    }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/Documents.vue'),
    meta: {
      title: 'Documents',
      auth: true
    }
  },
  {
    path: '/agenda-styles',
    name: 'AgendaStyles',
    component: () => import('@/views/Templates/AgendaStyles.vue'),
    meta: {
      title: 'Agenda Styles',
      auth: true
    }
  },
  {
    path: '/item-types',
    name: 'ItemTypes',
    component: () => import('@/views/Templates/ItemTypes.vue'),
    meta: {
      title: 'Item Types',
      auth: true
    }
  },
  {
    path: '/vote-format',
    name: 'VoteFormat',
    component: () => import('@/views/Templates/VoteFormats.vue'),
    meta: {
      title: 'Vote Format',
      auth: true
    }
  },
  {
    path: '/item-type-data-sections/:typeid',
    name: 'ItemTypeDataSections',
    props: true,
    component: () => import('@/views/Templates/ItemTypeDataSections.vue'),
    meta: {
      title: 'Item Type Data Sections',
      auth: true
    }
  },
  {
    path: '/item-type-cover-sheets/:typeid',
    name: 'ItemTypeCoverSheets',
    props: true,
    component: () => import('@/views/Templates/ItemTypeCoverSheets.vue'),
    meta: {
      title: 'Item Type Cover Sheets',
      auth: true
    }
  },
  {
    path: '/recurring-items',
    name: 'RecurringItems',
    component: () => import('@/views/Templates/RecurringItems.vue'),
    meta: {
      title: 'Recurring Items',
      auth: true
    }
  },
  {
    path: '/boards',
    name: 'Boards',
    component: () => import('@/views/Admin/Boards.vue'),
    meta: {
      title: 'Boards',
      auth: true
    }
  },
  {
    path: '/board-members/:id',
    name: 'BoardMembers',
    component: () => import('@/views/Admin/BoardMembers.vue'),
    meta: {
      title: 'Board Members',
      auth: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Admin/Users.vue'),
    meta: {
      title: 'Users',
      auth: true
    }
  },
  {
    path: '/workgroups',
    name: 'Workgroups',
    component: () => import('@/views/Admin/Workgroups.vue'),
    meta: {
      title: 'Workgroups',
      auth: true
    }
  },
  {
    path: '/approval-chains',
    name: 'ApprovalChains',
    component: () => import('@/views/Admin/ApprovalChains.vue'),
    meta: {
      title: 'Approval Chains',
      auth: true
    }
  },
  {
    path: '/auto-notifications',
    name: 'AutoNotifications',
    component: () => import('@/views/Admin/AutoNotifications.vue'),
    meta: {
      title: 'Auto-Notifications',
      auth: true
    }
  },
  {
    path: '/auto-notification-members',
    name: 'AutoNotificationMembers',
    component: () => import('@/views/Admin/AutoNotificationMembers.vue'),
    meta: {
      title: 'Auto-Notification Members',
      auth: true
    }
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: () => import('@/views/Admin/SocialMedia.vue'),
    meta: {
      title: 'Integrations',
      auth: true
    }
  },
  {
    path: '/general-settings',
    name: 'GeneralSettings',
    component: () => import('@/views/Admin/GeneralSettings.vue'),
    meta: {
      title: 'General Settings',
      auth: true
    }
  },
  {
    path: '/code-snippets',
    name: 'CodeSnippets',
    component: () => import('@/views/Admin/CodeSnippets.vue'),
    meta: {
      title: 'Code Snippets',
      auth: true
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('@/views/Support.vue'),
    meta: {
      title: 'Support',
      auth: true
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('@/views/GeneralLanding.vue'),
    meta: {
      title: 'Welcome',
      auth: true
    }
  },
  {
    path: '/join-organization',
    name: 'JoinOrganization',
    component: () => import('@/views/JoinOrganization.vue'),
    meta: {
      title: 'Join an Organization already on Agendease',
      auth: true
    }
  },
  {
    path: '/notification-signup',
    name: 'NotificationSignup',
    component: () => import('@/views/NotificationSignup.vue'),
    meta: {
      title: 'Signup to receive notifications of meetings from Organizations',
      auth: true
    }
  },
  {
    path: '/user-organizations',
    name: 'UserOrganizations',
    component: () => import('@/views/UserOrganizations.vue'),
    meta: {
      title: 'Your Organizations',
      auth: true
    }
  },
  {
    path: '/user-notifications',
    name: 'UserNotifications',
    component: () => import('@/views/UserNotifications.vue'),
    meta: {
      title: 'Your Notifications',
      auth: true
    }
  },
  {
    path: '*',
    name: 'catchAll',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['auth/isLogged']

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/')
    return
  }
  next()
})

export default router
